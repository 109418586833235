<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2 manage-shop-show w-75" v-loading="loaders.fetching">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <div>
                <h1>Medical Weight Loss Coordinator</h1>
            </div>
        </div>
        <medical-weight-loss-coordinator v-if="medicalWeightLossCoordinator" :medical-weight-loss-coordinator="medicalWeightLossCoordinator" />
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import MedicalWeightLossCoordinator from "@/views/Components/SuperAdmin/MedicalWeightLossCoordinator/Card";

export default {
    components: {
        BackButton,
        MedicalWeightLossCoordinator
    },
    data() {
        return {
            loaders: {
                fetching: false,
            },
            medicalWeightLossCoordinator: null,
        };
    },
    mounted() {
        this.getMedicalWeightLossCoordinator();
    },
    methods: {
        async getMedicalWeightLossCoordinator() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('_getMedicalWeightLossCoordinator');
                let {
                    data: { data },
                } = response;
                if(!data) this.$router.push({name:"admin.medical-weight-loss-coordinator.edit"})
                this.medicalWeightLossCoordinator = data;
            } catch (error) {
            }
            this.loaders.fetching = false;
        },
    },
};
</script>
<style scoped lang="scss"></style>
  